import * as React from "react";
import BannerCanceled from "~/components/BannerCanceled";
import BannerPastDue from "~/components/BannerPastDue";
import BannerSuspended from "~/components/BannerSuspended";
import BannerTrialWarning, {
  BannerExceedingSeatLimit,
  BannerFarExceedingSeatLimit,
  BannerLicenseExpired,
  BannerLicenseWarning,
} from "~/components/BannerTrialWarning";
import useCurrentTeam from "~/hooks/useCurrentTeam";
import useStores from "~/hooks/useStores";

export default function SubscriptionBanners() {
  const { ui, billing } = useStores();
  const team = useCurrentTeam({ rejectOnEmpty: false });

  const style = ui.sidebarCollapsed
    ? undefined
    : { marginLeft: `${ui.sidebarWidth}px` };

  if (!team) {
    return null;
  }

  return (
    <>
      {team.isCanceled ? (
        <BannerCanceled sidebarCollapsed={ui.sidebarCollapsed} style={style} />
      ) : team.isSuspended ? (
        <BannerSuspended sidebarCollapsed={ui.sidebarCollapsed} style={style} />
      ) : team.isPastDue ? (
        <BannerPastDue sidebarCollapsed={ui.sidebarCollapsed} style={style} />
      ) : billing.license?.isInGracePeriod ? (
        <BannerLicenseExpired
          sidebarCollapsed={ui.sidebarCollapsed}
          style={style}
        />
      ) : billing.license?.isFarExceedingSeatLimit ? (
        <BannerFarExceedingSeatLimit
          sidebarCollapsed={ui.sidebarCollapsed}
          style={style}
        />
      ) : billing.license?.isOverSeatLimit ? (
        <BannerExceedingSeatLimit
          userCount={billing.license.userCount}
          seatCount={billing.license.seatCount}
          sidebarCollapsed={ui.sidebarCollapsed}
          style={style}
        />
      ) : team.isNearTrialEnd ? (
        <BannerTrialWarning
          sidebarCollapsed={ui.sidebarCollapsed}
          daysLeftInTrial={team.daysLeftInTrial}
          style={style}
        />
      ) : team.isNearLicenseEnd ? (
        <BannerLicenseWarning
          sidebarCollapsed={ui.sidebarCollapsed}
          daysLeftInLicense={team.daysLeftInLicense}
          style={style}
        />
      ) : null}
    </>
  );
}
