import { observable } from "mobx";
import { observer } from "mobx-react";
import { CloseIcon } from "outline-icons";
import * as React from "react";
import styled from "styled-components";
import Flex from "~/components/Flex";
import Tooltip from "./Tooltip";

type Props = {
  id?: string;
  heading?: string;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
};

@observer
class Tip extends React.Component<Props> {
  @observable
  isHidden: boolean = window.localStorage.getItem(this.storageId) === "hidden";

  get storageId() {
    return this.props.id ? `tip-${this.props.id}` : "";
  }

  hide = () => {
    if (this.props.id) {
      window.localStorage.setItem(this.storageId, "hidden");
    }
    this.isHidden = true;
  };

  render() {
    const { id, heading, children, className, ...rest } = this.props;
    if (this.props.disabled || this.isHidden) {
      return null;
    }

    return (
      <Wrapper align="flex-start" className={className} {...rest}>
        <span>
          {heading && <Heading>{heading}</Heading>}
          <Content>{children}</Content>
        </span>

        {id && (
          <Tooltip content="Hide this message" placement="bottom">
            <Close size={32} color="#000" onClick={this.hide} />
          </Tooltip>
        )}
      </Wrapper>
    );
  }
}

const Close = styled(CloseIcon)`
  margin-top: 8px;
`;

const Wrapper = styled(Flex)`
  background: ${(props) => props.theme.accent};
  color: ${(props) => props.theme.white};
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 400;
  margin-bottom: 8px;
`;

const Heading = styled.h3`
  margin: 0.25em 0 0.5em 0;
`;

const Content = styled.p`
  margin: 0.25em 0;

  a {
    color: ${(props) => props.theme.white};
    text-decoration: underline;
  }
`;

export default Tip;
