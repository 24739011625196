import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Banner from "~/components/Banner";

type Props = React.ComponentProps<typeof Banner> & {
  className?: string;
};

function BannerCanceled(props: Props) {
  return (
    <Danger {...props}>
      Your subscription has been canceled, documents are now read-only, but can
      be exported. You can{" "}
      <Link to="/settings/billing">add payment details</Link> to restore
      service.
    </Danger>
  );
}

const Danger = styled(Banner)`
  background: ${(props) => props.theme.danger};
`;

export default BannerCanceled;
