import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Tip from "~/components/Tip";
import { draggableOnDesktop, undraggableOnDesktop } from "~/styles";

const Banner = styled(Tip)`
  ${draggableOnDesktop()}

  user-select: none;
  border-radius: 0;
  font-size: 15px;

  a {
    ${undraggableOnDesktop()}
    font-weight: 500;
  }

  ${breakpoint("mobile", "tablet")`
    margin-left: 0 !important;
  `};

  ${breakpoint("tablet")`
    padding: 8px 24px;
  `};
`;

export default Banner;
