import * as React from "react";
import { Link } from "react-router-dom";
import Banner from "~/components/Banner";

type Props = React.ComponentProps<typeof Banner> & {
  className?: string;
};

function BannerSuspended(props: Props) {
  return (
    <Banner {...props}>
      Your team has reached the end of the trial period. Please{" "}
      <Link to="/settings/billing">add payment details</Link> to continue using
      Outline.
    </Banner>
  );
}

export default BannerSuspended;
