import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Banner from "~/components/Banner";
import isCloudHosted from "~/utils/isCloudHosted";

type Props = React.ComponentProps<typeof Banner> & {
  className?: string;
};

function BannerPastDue(props: Props) {
  if (isCloudHosted) {
    return (
      <Banner {...props}>
        Your subscription payment failed, documents will soon become read-only.
        Please <Link to="/settings/billing">update your payment details</Link>{" "}
        to ensure no service interruption.
      </Banner>
    );
  }

  return (
    <Danger {...props}>
      Your license has expired, documents are now read-only. Please{" "}
      <a href="email:priority@getoutline.com">contact support</a> to arrange
      renewal.
    </Danger>
  );
}

const Danger = styled(Banner)`
  background: ${(props) => props.theme.danger};
`;

export default BannerPastDue;
