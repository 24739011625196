import { observer } from "mobx-react";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "sonner";
import { ExportContentType } from "@shared/types";
import Document from "~/models/Document";
import ConfirmationDialog from "~/components/ConfirmationDialog";
import Text from "~/components/Text";
import useCurrentUser from "~/hooks/useCurrentUser";
import usePersistedState from "~/hooks/usePersistedState";
import InputSelect from "./InputSelect";

type Props = {
  document: Document;
  onSubmit?: () => void;
};

function PDFExportDialog({ document, onSubmit }: Props) {
  const user = useCurrentUser();
  const [value, setValue] = usePersistedState(
    "pdf-export-paper-size",
    user.languageAsLocale === "en-US" ? "LETTER" : "A4"
  );
  const { t } = useTranslation();

  const handleSubmit = async () => {
    try {
      await document.download(ExportContentType.Pdf, value);
      onSubmit?.();
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <ConfirmationDialog
      onSubmit={handleSubmit}
      submitText={t("Export")}
      savingText={`${t("Exporting")}…`}
    >
      <Text type="secondary" size="small">
        <Trans>Choose a size for your exported document</Trans>
      </Text>
      <InputSelect
        ariaLabel={t("Paper size")}
        value={value}
        onChange={setValue}
        options={[
          { value: "LEGAL", label: "Legal" },
          { value: "LETTER", label: "Letter" },
          { value: "TABLOID", label: "Tabloid" },
          { value: "A0", label: "A0" },
          { value: "A1", label: "A1" },
          { value: "A2", label: "A2" },
          { value: "A3", label: "A3" },
          { value: "A4", label: "A4" },
          { value: "A5", label: "A5" },
        ]}
      />
    </ConfirmationDialog>
  );
}

export default observer(PDFExportDialog);
